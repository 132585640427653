import React from "react";
import { useHomeData } from "../hooks/use-home-data"

const SectionHomePage1 = () => {
  const { sectionOne } = useHomeData();
  return (
    <section className="home-page-section-1">
      <div className="container">
        <div className="row">
          <div className="col-100 col-small-1-2 vertical-align-middle">
            <h1 className="text-align-center text-align-small-left">
              {sectionOne.title}
            </h1>
            <div className="p">{sectionOne.text}</div>
            <footer>
              <a href={sectionOne.linkUrl} className="button">{sectionOne.linkText}</a>
            </footer>
          </div>
          <div className="col-100 col-small-1-2 vertical-align-middle">
            <div className="section-image-container">
              <img src={sectionOne.image.childImageSharp.fixed.src} alt={sectionOne.imageAlt} className="section-image" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default SectionHomePage1
