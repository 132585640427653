import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/hero'
import BookingModule from '../components/booking-module'
import SectionHomePage1 from "../components/section-home-page-1"
import SectionHomePage2 from "../components/section-home-page-2"

class Index extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Niwot Inn &amp; Spa" />
        <Hero />
        <BookingModule />
        <SectionHomePage1 />
        <SectionHomePage2 />
      </Layout>
    )
  }
}

export default Index

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
