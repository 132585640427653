import React from "react";
import { Link } from "gatsby"
import { useHomeData } from "../hooks/use-home-data"

const Hero = () => {
  const { hero } = useHomeData();
  return (
    <section className="hero">
      <div className="hero-background" style={{backgroundImage: "url(" + hero.image.childImageSharp.fixed.src + ")"}}>
        <div className="gradient-overlay"></div>
      </div>
      <div className="hero-content container">
        <div className="row">
          <div className="col-0 col-medium-1-10"></div>
          <div className="col-100 col-medium-8-10">
            <div className="row">
              <div className="col-100 col-small-1-2 vertical-align-middle text-align-center text-align-small-left">
                <img className="niwot-inn-text" src="/images/niwot-inn-text.svg" />
                <span className="icon-niwot-inn-text"></span>
              </div>
              <div className="col-100 col-small-1-2 vertical-align-middle text-align-right text-align-center text-align-small-right">
                <Link to={hero.linkUrl} id="stayWithUsCTA" className="hero-button no-hover">
                  {hero.linkText}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero
