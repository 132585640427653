import { useStaticQuery, graphql } from "gatsby"
export const useHomeData = () => {
  const data = useStaticQuery(
    graphql`
    {
        pagesHomeYaml {
          hero {
            image {
              childImageSharp {
                fixed(height: 600, toFormat: JPG, quality: 30) {
                  src
                }
              }
            }
            imageAlt
            linkText
            linkUrl
          }
          sectionOne {
            imageAlt
            linkText
            linkUrl
            text
            title
            image {
              childImageSharp {
                fixed(width: 620, quality: 30, toFormat: JPG) {
                  src
                }
              }
            }
          }
        }
      }
    `
  )
  return data.pagesHomeYaml
}