import React from "react";
import Siema from 'siema';
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
class SectionHomePage1 extends React.Component {

  prev = () => {
    this.siema.prev()
  };

  next = () => {
    this.siema.next()
  };

  render() {

    const CardsComponent = () => {

      const data = useStaticQuery(
        graphql`
            query {
                allMarkdownRemark( filter: {frontmatter: { path: {eq: "/rooms"} }} ) {
                    edges {
                        node {
                            excerpt
                            fields {
                                slug
                            }
                            frontmatter {
                                title
                                description
                                category
                                mainImage {
                                    childImageSharp {
                                        fluid {
                                            ...GatsbyImageSharpFluid
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const rooms = data.allMarkdownRemark.edges;
      return (
        <div className="siema">
          {rooms.map(({ node }, i) => (
            <div className="col-100 card-container" key={i}>
              <div className="card room-card">
                <div className="image-container">
                  <div className="image-container-inside">
                    <Img
                      fluid={ node.frontmatter.mainImage.childImageSharp.fluid }
                      objectFit="cover"
                      objectPosition="50% 50%"
                      className="card-image"
                      alt={ node.fields.slug }
                    />
                  </div>
                </div>
                <h3>{ node.frontmatter.title || node.fields.slug }</h3>
                <div className="p">{ node.frontmatter.description }</div>
                <footer>
                  <Link to={ node.fields.slug } className="button pull-right">View Room</Link>
                </footer>
              </div>
            </div>
          ))}
        </div>
      )
    }

    return (
      <section className="home-page-section-2 darker">
        <div className="container room-slider-container">
          <div className="row">
            <div className="col-1-12 vertical-align-middle position-relative p-0 left-button-container">
              <button className="left-chevron-button room-slider-back" onClick={this.prev}>
                <span className="icon-chevron-left"></span>
              </button>
            </div>
            <div className="col-100 col-small-10-12 vertical-align-middle p-0">
              <CardsComponent />
            </div>
            <div className="col-1-12 vertical-align-middle position-relative p-0 right-button-container">
              <button className="right-chevron-button room-slider-next" onClick={this.next}>
                <span className="icon-chevron-right"></span>
              </button>
            </div>
          </div>
        </div>
      </section>
    )
  }
  componentDidMount() {
    this.siema = new Siema({
      perPage: {
          767: 2,
          1105: 2,
          1300: 3,
      },
      loop: true,
      onInit: () => {
        var maxCardHeight = 0;
        var roomCards = document.querySelectorAll('.room-card');
        roomCards.forEach(function(card) {
          if (card.offsetHeight > maxCardHeight) {
            maxCardHeight = card.offsetHeight;
          }
        });
        roomCards.forEach(function(card) {
          card.style.height = maxCardHeight + 'px';
        });
      }
    });
  }
}

export default SectionHomePage1
